import $ from 'jquery';

$.fn.cookies = function(options) {

    // Default options
    var settings = $.extend({
        cookieSelector: $(this),
        cookieContainerSelector: '.cookie-container',
        hideCookieClass: 'hide-cookie',
        cookiePopupStateName: 'cookie-popupState',
        cookiePopupState: false,
        cookieAcceptSelector: '#acceptCookies',
        cookieDeclineSelector: '#declineCookies',
        cookieSettingsSelector: '#openCookieSettings',
        cookieOpenLinks: 'a[href="#openCookieBar"]',
        cookieExpiry: 14,
        cookiePath: '/'
    }, options );

    // Init
    function init()
    {
        $(settings.cookieSelector).find(settings.cookieAcceptSelector).on('click', function(){
            setCookie(settings.cookiePopupStateName, true);
            setSelectedCookies();
            toggleNotice();
            //reload page, to view/hide elements which were viewn/hidden (decomment the reload line, if there are no elements which are basically hidden)
            var currentUrlWithParams = window.location.href; // Erfassung der aktuellen URL mit Parametern
            window.location.href = currentUrlWithParams; // Setzen der aktuellen URL, um die Seite mit den vorhandenen Parametern neu zu laden
        });

        $(settings.cookieSelector).find(settings.cookieDeclineSelector).on('click', function(){
            setCookie(settings.cookiePopupStateName, true);
            toggleNotice();
        });

        $(settings.cookieSelector).find(settings.cookieSettingsSelector).on('click', function(){
            toggleSettings();
        });

        $('body').find(settings.cookieOpenLinks).on('click', function(){
            toggleNotice();
        });

        if(getCookie(settings.cookiePopupStateName) !== 'true') {
            //setSelectedCookies();
            toggleNotice();
        }

    }

    function toggleNotice()
    {
        $(settings.cookieSelector).toggleClass(settings.hideCookieClass);
    }

    function toggleSettings()
    {
        $(settings.cookieContainerSelector).toggleClass(settings.hideCookieClass);
    }

    function setCookie(name, value)
    {
        var expires = new Date();
        expires.setTime(expires.getTime() + (settings.cookieExpiry * 24 * 60 * 60 * 1000));
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=' + settings.cookiePath;
    }

    function getCookie(name)
    {
        var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? value[2] : null;
    }

    function setSelectedCookies()
    {
        $(settings.cookieContainerSelector + ' input:checkbox:checked').each(function () {
            var value = $(this).val();
            setCookie(value, true);
        });

        $(settings.cookieContainerSelector + ' input:checkbox:not(:checked)').each(function () {
            var value = $(this).val();
            setCookie(value, false);
        });
    }

    // Init plugin
    return init();
};